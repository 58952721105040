import Web3 from "web3";
import BigNumber from "bignumber.js";

const contractList = {};
let _web3 = null;
let _wallet = null;
let _chainId = 1;
// const rpcETHUrl = process.env.VUE_APP_ETH_RPC_URL;
const rpcBSCUrl = process.env.VUE_APP_BSC_RPC_URL;

const chainConfig = {
    // 1: {
    //     rpcUrl: rpcETHUrl,
    //     name: "Ethereum",
    //     web3: null,
    //     dWeb3: null,
    //     ido: "",
    //     usdt: ""
    // },
    56: {
        rpcUrl: rpcBSCUrl,
        name: "BNB Chain",
        web3: null,
        dWeb3: null,
        ido: "0xb1bF6513F6a88bFe97c2bAcc45a799EF36ec6469",
        usdt: "0x55d398326f99059fF775485246999027B3197955",
        faker: "0xC20F9B744987397B18B29171fA9EbBFcBAC5A15A",
        refund: "0xC821e0Fa112ae55B6f26711B0b65F5e50586ac02",
    },
    // 97: {
    //     rpcUrl: rpcBSCUrl,
    //     name: "BNB Chain",
    //     web3: null,
    //     dWeb3: null,
    //     ido: "0x5cE2FC5c953cbd734A0F87DfFc02de304ED665bC",
    //     usdt: "0x5168f9AA9C25fA61935DA3EBDa2c77dDeD98Da9b"
    // },
}

export const usdtAddr = () => {
    let chainId = _chainId;
    if (chainConfig[chainId]) {
        return  chainConfig[chainId].usdt;
    }
    return ""
}

export const idoAddr = () => {
    let chainId = _chainId;
    if (chainConfig[chainId]) {
        return  chainConfig[chainId].ido;
    }
    return ""
}

export const getContract = (tokenTag, abi) => {
    let chainId = _chainId;
    if (chainConfig[chainId]) {
        let contc = contractList[tokenTag];
        if (contc) {
            return contc;
        }
        try {
            let _web3 = web3();
            contc = new _web3.eth.Contract(abi, chainConfig[chainId][tokenTag]);
            contractList[tokenTag] = contc;

            return contc;
        } catch (e) {
            console.log(e)
            return null;
        }
    } else {
        return null;
    }

}

export const listenWalletChanged = async (update) => {
    try {
        if (!Web3.givenProvider) {
            return;
        }
        const provider = Web3.givenProvider;

        setTimeout(()=>{
            _chainId = new BigNumber(provider.chainId).toNumber();
            console.log(_chainId)
            connect().then(()=>{
                update?.call();
            });
        }, 300)

        if (provider) {
            provider.on('accountsChanged', function (accounts) {
                if (_wallet != accounts[0]) {
                    _wallet = accounts[0];
                }
                console.log(accounts)
                update?.call();
            });
            provider.on("networkChanged", function (networkIDstring) {
                console.log(networkIDstring)
                _chainId = networkIDstring;
                connect().then(()=>{
                    update?.call();
                });
            })
        }

    } catch (error) {
        console.log(error)
    }
}

export const connect = async () => {
    try {
        let wallets = await web3().eth.requestAccounts();
        _wallet = wallets[0];
        return true
    } catch (e) {
        console.log(e)
        return false
    }
}

export const fixWallet = () => {
  if (_wallet && _wallet != null && _wallet.length > 10) {
      return _wallet.substr(0, 4) +"...." + _wallet.substr(_wallet.length-4, _wallet.length)
  }
  return "Connect"
}

export const getWallet = () => {
    let chainId = _chainId;
    if (!chainConfig[chainId]) {
        return "Wrong Network";
    }
  return _wallet;
}

const web3 = () => {
    let chainId = _chainId;
    if (chainConfig[chainId]) {
        let configInfo = chainConfig[chainId];
        if (Web3.givenProvider) {
            if (configInfo.web3) {
                return configInfo.web3;
            }
            _web3 = new Web3(Web3.givenProvider);
            configInfo.web3 = _web3;
        }
        else {
            if (configInfo.web3) {
                return configInfo.web3;
            }
            _web3 = new Web3(Web3.givenProvider);
            configInfo.web3 = _web3;
        }
        return _web3;
    }
    return null;

}

export const isAddress = (addr) => {
    try {
        let _web3 = web3();
        return  _web3.utils.isAddress(addr);
    } catch (e) {
        return false;
    }
}

export const chainName = () => {
    let chainId = _chainId;
    if (chainConfig[chainId]) {
        let configInfo = chainConfig[chainId];
        return configInfo.name;
    }
    return "Ethereum";
}