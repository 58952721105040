<template>
  <div class="hello">
    <div>
      <div style="position: relative; top: 0; left: 0; width: 100%">
        <img src="../assets/ibg.png" style="width: 100%; height: 100%">
        <div class="top-container" style="position:absolute; top: 0; left: 0; right: 0">
          <div class="name-row" >
            <img src="../assets/logo.png" width="60px" height="60px" style="border-radius: 100px">
            <div class="name-flex-value"/>
            <div @click="languagechange" class="top-button" style="margin-top: 10px;">
              <div class="name-row name-flex-center" style="height: 100%;">{{ language }}</div>
            </div>
            <div class="top-button" style="margin-top: 10px; margin-left: 10px;">
              <div @click="connect" class="name-column name-column-centerRight" style="height: 100%; padding-left: 5px; padding-right: 5px">{{fixWallet}}</div>
            </div>
          </div>
        </div>
        <div class="top-container" style="position:absolute; bottom: 0; left: 0; right: 0">
          <div class="tokens font-size-36" style=" -webkit-text-fill-color: rgba(162,209, 19, 1);-webkit-text-stroke: 1px white; font-weight: bolder">
            {{$t('Token’s more Ecological')}}
          </div>
          <div class="height-20"/>
          <div class="name-row name-flex-center">
            <div class="name-row clock-container">
              <div style="padding-right: 10px">
                <div style="height: 1px"/>
                <div class="font-size-20" style=" font-weight: bold; color: white">{{ days }}</div>
                <div class="font-size-10" style=" line-height: 10px; color: #FFFFFF66">{{$t('Days')}}</div>
                <div style="height: 3px"/>
              </div>
              <div style="padding-right: 10px">
                <div style="height: 1px"/>
                <div class="font-size-20" style=" font-weight: bold; color: white">{{ hours }}</div>
                <div class="font-size-10" style=" line-height: 10px; color: #FFFFFF66">{{$t('Hours')}}</div>
                <div style="height: 3px"/>
              </div>
              <div>
                <div style="height: 1px"/>
                <div class="font-size-20" style="font-weight: bold; color: white">{{ minutes }}</div>
                <div class="font-size-10" style=" line-height: 10px; color: #FFFFFF66">{{$t('Minutes')}}</div>
                <div style="height: 3px"/>
              </div>
            </div>
          </div>
          <div class="height-20"/>
          <div style="height: 16px"/>
        </div>
      </div>

    </div>
    <div style="position:relative; top: -6px; width: 100%">
<!--      <img src="../assets/ibgc.png" width="100%" style="object-fit: cover; object-position: center top">-->
      <div class="top-container bci" style="
      /*position: absolute; top: 0; left: 0; right: 0;*/
">
        <div style="max-width: 1000px; width: 100%; margin: auto">
<!--          <div style="position: absolute; font-size: 10px; top: -16px; color: white; font-weight: bold; width: 100%; margin-left: 0px; background:red;">-->
<!--            <div>-->
<!--              Hardcap: <span style="color: rgba(162,209, 19, 1)">200,000</span> USDT-->
<!--            </div>-->
<!--          </div>-->
          <div style="height: 3px"/>

          <div style="height: 16px"/>
          <div class="text-bold">
            <span style="color: rgba(162,209, 19, 1)">暂停IDO，等待退款</span>
          </div>
          <div style="height: 16px"/>
<!--          <div class="name-row">-->
<!--            <div class="text-bold">{{$t('Min')}}:<span style="color: rgba(162,209, 19, 1)"> 100 </span>{{ quoteTokenName }}</div>-->
<!--            <div class="name-flex-value">-->

<!--            </div>-->
<!--            <div class="text-bold">{{$t('Max')}}:<span style="color: rgba(162,209, 19, 1)"> 2000 </span>{{quoteTokenName}}</div>-->
<!--          </div>-->
<!--          <div style="height: 10px"/>-->
<!--          <div class="name-row">-->
<!--            <el-input v-model="buyValue" @input="matchBuyValue($event)"-->
<!--            >-->
<!--              <template slot="append">{{ quoteTokenName }}</template>-->
<!--            </el-input>-->
<!--&lt;!&ndash;            <div class="name-row name-flex-center">&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="name-row name-row-centerLeft name-flex-value" style="border:rgba(162,209, 19, 1) solid 1px; height: 100%">&ndash;&gt;-->
<!--&lt;!&ndash;                <input v-model="buyValue" @input="matchBuyValue($event)" style="height: 100%"/>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div>{{quoteTokenName}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--            <div style="width: 3px"/>-->
<!--            <el-button @click="deposit" type="success" :style="{opacity: buyValue >= 100 && timesClock > 0 ? 0.35 : 0.35}">Pay</el-button>-->
<!--          </div>-->
<!--          <div style="height: 3px"/>-->
<!--          <div  class="text-bold" style="text-align: left">-->
<!--            {{$t('Receive')}} {{buyValue > 0 ? fixAmount((Math.floor(buyValue*1e4*100)/1e4)) : 100}} {{ tokenName }} {{buyValue > 0 ? "" : "/ 1 "+quoteTokenName}}-->
<!--          </div>-->
          <div style="height: 10px"/>

          <div style="text-align: left;">
            <div class="name-row name-row-centerLeft" style="height: 40px;">
              <div class="text-bold" >{{$t('Refund balance')}}: {{refundInfo.refunded ? 0 : fixBalance(refundInfo.amount)}} USDT</div>
              <div class="name-flex-value"/>
              <el-button @click="refund" type="success" :style="{opacity: refundInfo.amount > 0 && !refundInfo.refunded ? 1 : 0.35}">{{refundInfo.refunded ? $t('Claimed') : $t('Claim')}}</el-button>
            </div>
<!--            <div class="title">VELD</div>-->
<!--            <div class="name-row name-row-centerLeft text-bold" style="height: 40px;">{{$t('Total supply')}}: {{fixBalance(userInfo.totalUsdt*100/40*70)}}</div>-->
<!--            <div class="name-row name-row-centerLeft" style="height: 40px;">-->
<!--              <div class="text-bold" >{{$t('Your balance')}}: {{fixBalance(userInfo.canClaimValue)}}</div>-->
<!--              <div class="name-flex-value"/>-->
<!--              <el-button @click="claim" type="success" :style="{opacity: userInfo.canClaimValue > 0 && userInfo.openClaim ? 1 : 0.35}">{{userInfo.claimed ? $t('Claimed') : $t('Claim')}}</el-button>-->
<!--            </div>-->
<!--            <div style="height: 20px"/>-->
<!--            <div class="name-row name-row-centerLeft" style="height: 40px;">-->
<!--              <div class="text-bold" >{{$t('Your Reward')}}: <span style="color: rgba(162,209, 19, 1)">{{fixBalance(userInfo.inviterValue) }}</span> USDT</div>-->
<!--              <div class="name-flex-value"/>-->
<!--              <el-button @click="claimInviterAmount" type="success" :style="{opacity: userInfo.inviterValue >0 && userInfo.openClaimIV && !userInfo.claimedIV ? 1 : 0.35}">{{userInfo.claimedIV ? $t('Claim Reward') : $t('Claim Reward2')}}</el-button>-->
<!--            </div>-->
<!--            <div style="height: 20px"/>-->
<!--            <div class="name-flex name-flex-wrap-wrap" style="min-height: 40px;">-->
<!--              <div class="text-bold" style="word-break: break-word">{{$t('Invite link')}}: <span style="color: rgba(162,209, 19, 1)">https://{{localHost}}/{{wallet}}</span></div>-->
<!--            </div>-->
<!--            <div style="height: 20px"/>-->
<!--            <div class="name-flex name-flex-wrap-wrap" style="height: 40px; width: 100%">-->
<!--              <div class="text-bold" >{{$t('Invitations')}}: <span style="color: rgba(162,209, 19, 1)">{{userInfo.inviterNum}}</span></div>-->
<!--            </div>-->

          </div>
          <div style="height: 20px"/>
          <div class="name-row">
            <el-input v-model="redeemVeld" @input="matchRedeemValue($event)" :disabled="true"
                      :placeholder="$t('Coming Soon')"
            >
              <template slot="append">{{ tokenName }}</template>
            </el-input>
            <div style="width: 3px"/>
            <el-button type="success" :style="{opacity: redeemVeld > 0 ? 1 : 0.35}">{{$t('Redeem')}}</el-button>
          </div>
          <div style="height: 3px"/>
          <div class="text-bold" style="text-align: left">
            {{$t('Burn')}} {{redeemVeld?redeemVeld:0}} {{ tokenName }} to {{$t('Receive')}} {{ fixAmount(Math.floor(redeemVeld*1e2/100)/1e2) }} {{quoteTokenName}}
          </div>
          <div style="height: 20px"/>
          <div class="name-row">
            <el-input v-model="mortgageVeld" @input="matchMortgageValue($event)" :disabled="true"
                      :placeholder="$t('Coming Soon')"
            >
              <template slot="append">{{ tokenName }}</template>
            </el-input>
            <div style="width: 3px"/>
            <el-button type="success" :style="{opacity: mortgageVeld > 0 ? 1 : 0.35}">{{$t('Lend')}}</el-button>
          </div>
          <div style="height: 3px"/>
          <div  class="text-bold" style="text-align: left">
            {{$t('Receive')}}  {{ fixAmount(Math.floor(mortgageVeld*1e2/100)/1e2) }} {{ quoteTokenName }}
          </div>
          <div style="height: 20px"/>
          <div>
<!--            &lt;!&ndash;          Token Distribution and Economic Plans&ndash;&gt;-->
<!--            <div class="title" style="text-align: left">{{$t('Token Distribution and Economic Plans')}}</div>-->
<!--            <div style="max-width: 60%; margin: auto">-->
<!--              <el-image-->
<!--                  style="width: 100%; height: auto"-->
<!--                  :src="tokenImage"-->
<!--                  :preview-src-list="[tokenImage]"-->
<!--                  :z-index="0"-->
<!--              >-->
<!--              </el-image>-->

<!--            </div>-->
<!--          </div>-->
<!--          <div>-->
<!--            &lt;!&ndash;          Distribution of IDO’ETH Revenue&ndash;&gt;-->
<!--            <div class="title" style="text-align: left">{{$t('Distribution of IDO Revenue')}}</div>-->
<!--            <div style="max-width: 60%; margin: auto">-->
<!--              <el-image-->
<!--                  style="width: 100%; height: auto"-->
<!--                  :src="ethImage"-->
<!--                  :preview-src-list="[ethImage]"-->
<!--              >-->
<!--              </el-image>-->
<!--            </div>-->
            <div style="max-width: 1000px; margin: auto">
              <el-image class="mobile"
                        style="width: 100%; height: auto"
                        :src="ruleImage(false)"
                        :preview-src-list="[ruleImage(false)]"
              />
              <div class="name-row name-flex-center">
                <el-image class="pc"
                          style="width: 100%; height: auto"
                          :src="ruleImage(true)"
                          :preview-src-list="[ruleImage(true)]"
                />
              </div>
            </div>
          </div>

          <div>
            <el-timeline class="timeline">
              <div>
                <div class="timeline-container">
                  <div class="timeline-tag-container">
                    <div style="width: 13px; height: 13px; border-radius: 100px; background-color: #C0C4CC"></div>
                    <div class="timeline-tag-line-container">
                      <div class="timeline-tag-line"></div>
                    </div>
                  </div>
                  <div class="timeline-content-container">
                    <div style="margin-bottom: 10px; text-align: left">2022 Q2</div>
                    <div class="card">
                      <p style="">{{$t('Swap')}}</p>
                      <h4 style="line-height: 8px">{{$t('Easy Create Token')}}</h4>
                      <p style="line-height: 8px">{{$t('Multi Send Token')}}</p>
                      <p style="line-height: 8px">{{$t('IDO')}}</p>
                    </div>
                  </div>
                </div>
                <div style="height: 10px"/>
              </div>
              <div>
                <div class="timeline-container">
                  <div class="timeline-tag-container">
                    <div style="width: 13px; height: 13px; border-radius: 100px; background-color: #C0C4CC"></div>
                    <div class="timeline-tag-line-container">
                      <div class="timeline-tag-line"></div>
                    </div>
                  </div>
                  <div class="timeline-content-container" >
                    <div style="margin-bottom: 10px; text-align: left">2022 Q3</div>
                    <div class="card">
                      <h4 style="line-height: 8px">{{$t('Create NFT')}}</h4>
                      <p style="line-height: 8px">{{$t('NFT Trade')}}</p>
                    </div>
                  </div>
                </div>
                <div style="height: 10px"/>
              </div>

              <div class="timeline-container">
                <div class="timeline-tag-container">
                  <div style="width: 13px; height: 13px; border-radius: 100px; background-color: #C0C4CC"></div>
                </div>
                <div class="timeline-content-container">
                  <div style="margin-bottom: 10px; text-align: left">2022 Q4</div>
                  <div class="card">
                    <h4 style="line-height: 8px">{{$t('Token Robot')}}</h4>
                    <p style="line-height: 8px">{{$t('Wallet Listener')}}</p>
                  </div>
                </div>
              </div>

            </el-timeline>
          </div>

          <div style="height: 60px"/>
          <div class="name-row">
            <div class="name-flex-value"/>
            <div>
              <img @click="openLink('https://twitter.com/VeldDAO')" src="../assets/twitter.png" width="60px" height="60px">
            </div>
            <div style="margin-left: 20px">
              <img @click="openLink('https://t.me/VeldDAO_CN')" src="../assets/telegram.png" width="60px" height="60px">
            </div>
            <div style="margin-left: 20px">
              <img @click="openDiscord" src="../assets/discord.png" width="60px" height="60px">
            </div>
          </div>
          <div style="height: 60px"/>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {
  chainName,
  connect,
  fixWallet,
  getContract,
  getWallet,
  idoAddr, isAddress,
  listenWalletChanged
} from "../utils/contract";
import IdoAbi from "../assets/abi/IdoAbi.json"
import ERC20Abi from "../assets/abi/ERC20Abi.json"
import fakerAbi from "../assets/abi/fakerAbi.json"
import RefundAbi from "../assets/abi/RefundAbi.json"
import BigNumber from "bignumber.js";
import i18n from "../i18n";
import {Toast} from "vant";


export default {
  name: 'Service',
  props: {
    msg: String
  },
  watch: {

  },
  data() {
    return {
      chainName: "ETH",
      fixWallet: "",
      wallet: "",
      buyValue: "100",
      redeemVeld: "",
      mortgageVeld: "",
      tokenName: "VELD",
      quoteTokenName: "USDT",
      lang: "",
      timesClock: 0,
      totalUsdt: 0,
      userInfo: {
        depositValue: 0,
        claimValue: 0,
        claimed: false,
        tokenTotal: 0,
        inviter: "",
        inviterValue: 0,
        claimedIV: false,
        totalUsdt: 0,
        canClaimValue: 0,
        inviterNum: 0,
        openClaim: false,
        openClaimIV: false,
      },
      refundInfo: {
        amount: 0,
        refunded: true,
      },
      localHost: "",
    }
  },
  mounted() {
    let self = this;
    self.chainName = chainName();
    self.fixWallet = fixWallet();
    self.wallet = getWallet();

    listenWalletChanged(()=>{
      self.chainName = chainName();
      self.fixWallet = fixWallet();
      self.wallet = getWallet();
    });

    setInterval(() => {
      try {
        self.loadValues();
      } catch {
        console.log()
      }
    }, 3000);

    this.localHost = window.location.host;
    this.inviter = window.location.pathname;
    if (this.inviter != undefined) {
      this.inviter = this.inviter.replace("/", "");
    }
  },
  computed: {
    language() {
      let _lan;
      if (this.lang === "") {
        _lan = localStorage.getItem("lang");
      }
       let lan = this.lang||_lan||"en";
       if (lan == "en") {
         return "EN/中";
       }
       return  "中/EN";
    },
    ethImage() {
      return process.env.VUE_APP_FILE_PREFIX+"img/veld/token/ETH.png";
    },
    tokenImage() {
      return process.env.VUE_APP_FILE_PREFIX+"img/veld/token/Token.png";
    },
    ruleImage() {
      return (isVertical) => {
        if (isVertical) {

          return process.env.VUE_APP_FILE_PREFIX+"img/veld/token/"+this.$t("VeldRule-vertical")+".png";
        }
        return process.env.VUE_APP_FILE_PREFIX+"img/veld/token/"+this.$t("VeldRule")+".png";
      }
    },
    fixAmount() {
      return (amount) => {
        if (!amount) {
          return "0"
        }
        try {
          return new BigNumber(amount).toString();
        } catch (e) {
          return "0"
        }
      }
    },
    fixBalance() {
      return (amount) => {
        try {
          let result = Math.floor(new BigNumber(amount).div(1e14).toNumber())/1e4;
          if (Number.isNaN(result)) {
            return "0";
          }
          return result;
        } catch (e) {
          return "0"
        }
      }
    },
    days() {
      const _days = 60*60*24;
      let result = Math.floor(this.timesClock/_days);
      if (result >= 10) {
        return result+"";
      }
      return "0"+result;
    },
    hours() {
      const _days = 60*60*24;
      const _hours = 60*60;
      let result = Math.floor(this.timesClock%_days/_hours);
      if (result >= 10) {
        return result+"";
      }
      return "0"+result;
    },
    minutes() {
      const _days = 60*60*24;
      const _hours = 60*60;
      let result = Math.floor(this.timesClock%_days%_hours/60);
      if (result >= 10) {
        return result+"";
      }
      return "0"+result;
    },
  },
  methods: {
    openLink(link) {
      open(link);
    },
    openDiscord() {
      open("https://discord.gg/vVsPZDyQAE");
    },
    languagechange() {
      let lan = localStorage.getItem("lang") || "en";
      if (lan == "en") {
        lan = "zh";
        localStorage.setItem("lang", lan);
      } else {
        lan = "en";
        localStorage.setItem("lang", lan);
      }
      this.lang = lan;
      i18n.locale = lan;
    },
    async connect() {
      await connect();
      let self = this;
      self.chainName = chainName();
      self.fixWallet = fixWallet();
      self.wallet = getWallet();

    },
    matchBuyValue(value) {
      value = value.replace(/[^\d.]/g,"")
      this.buyValue = value;
    },
    matchRedeemValue(value) {
      value = value.replace(/[^\d.]/g,"")
      this.redeemVeld = value;
    },
    matchMortgageValue(value) {
      value = value.replace(/[^\d.]/g,"")
      this.mortgageVeld = value;
    },
    async loadValues() {
      const idoContract = getContract("ido", IdoAbi);
      const fakerContract = getContract("faker", fakerAbi);
      if (!idoContract) return;
      const startTime = await idoContract.methods.startTime().call();
      const keepTime = await idoContract.methods.keepTime().call();

      let now = Math.floor(Date.now()/1000);

      if (new BigNumber(startTime+"").plus(keepTime+"").lte(now+"")) {
        this.timesClock = 0;
      } else {
        this.timesClock = Math.floor(new BigNumber(startTime+"").plus(keepTime+"").minus(now+"").toNumber());
      }
      let _fakerNum = await fakerContract.methods.fakerNum().call();
      this.userInfo = await idoContract.methods.getUserInfo(this.wallet).call();
      let totalUsdt = new BigNumber(this.userInfo.totalUsdt).plus(_fakerNum).toString();
      this.totalUsdt = totalUsdt;

      const refundContract = getContract("refund", RefundAbi);
      let refundInfo = await refundContract.methods.refundInfo().call({
        from: this.wallet,
      });
      this.refundInfo = refundInfo;
      // console.log(refundInfo)
    },
    async deposit() {
      console.log(2)
      if (this.timesClock == 0) {
        return;
      }
      if (this.timesClock > 0) {
        return;
      }
      Toast.loading({
        message: 'loading...',
        loadingType: 'spinner',
        duration: 0,
      })
      try {
        const idoContract = getContract("ido", IdoAbi);
        const zeroAddress = "0x0000000000000000000000000000000000000000";
        let inviterAddress = zeroAddress;
        if (isAddress(this.inviter)) {
          inviterAddress = this.inviter;
        }
        let buyValueBN = new BigNumber(this.buyValue+"").times(1e18);
        if (buyValueBN.lt(100*1e18)) {
          Toast.clear();
          return;
        }
        if (buyValueBN.gt(2000*1e18)) {
          Toast.clear();
          return;
        }
        const max = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
        const usdtContract = getContract("usdt", ERC20Abi);
        const approveValue = await usdtContract.methods.allowance(this.wallet, idoAddr()).call();
        if (new BigNumber(approveValue).lt(buyValueBN)) {
          let result = await usdtContract.methods.approve(idoAddr(), max).send({
            from: this.wallet
          });
          if (result.status == false) {
            this.$message.error("approve USDT fail");
            Toast.clear();
            return
          }
        }
        console.log(inviterAddress)
        await idoContract.methods.deposit(inviterAddress, this.buyValue+"000000000000000000").estimateGas({
          gas: 5000000,
          from: this.wallet
        });
        console.log(this.buyValue+"000000000000000000")
        const result = await idoContract.methods.deposit(inviterAddress, this.buyValue+"000000000000000000").send({
          from: this.wallet
        });
        if (result.status == true) {
          console.log("Deposit success");
          this.$message.success("Deposit success")
        } else {
          console.log("Deposit fail");
        }
        Toast.clear();
      } catch (e) {
        console.log(e)
        let errorList = e.message.replace("Internal JSON-RPC error.", "").split("execution reverted:");
        if (errorList.length > 1) {
          let result = errorList[1].split("\",")[0];
          this.$message.error(result)
        } else {
          this.$message.error("Deposit fail")
        }
        Toast.clear();
      }
    },
    async claim() {

      if (this.userInfo.depositValue <= 0 || this.userInfo.claimed) {
        return
      }

      Toast.loading({
        message: 'loading...',
        loadingType: 'spinner',
        duration: 0,
      })
      try {
        const idoContract = getContract("ido", IdoAbi);
        await idoContract.methods.claim().estimateGas({
          gas: 5000000,
          from: this.wallet
        });
        const result = await idoContract.methods.claim().send({
          from: this.wallet
        });
        if (result.status == true) {
          console.log("Claim success");
          this.$message.success("Claim success")
          Toast.clear();
        } else {
          console.log("Claim fail");
          Toast.clear();
        }
      } catch (e) {
        let errorList = e.message.replace("Internal JSON-RPC error.", "").split("execution reverted:");
        if (errorList.length > 1) {
          let result = errorList[1].split("\",")[0];
          this.$message.error(result)
        } else {
          this.$message.error("Claim fail")
        }
        Toast.clear();
      }
    },
    async claimInviterAmount() {
      if (this.userInfo.inviterValue <= 0 || this.userInfo.claimedIV) {
        return
      }
      Toast.loading({
        message: 'loading...',
        loadingType: 'spinner',
        duration: 0,
      })
      try {
        const idoContract = getContract("ido", IdoAbi);
        await idoContract.methods.claimInviterAmount().estimateGas({
          gas: 5000000,
          from: this.wallet
        });
        const result = await idoContract.methods.claimInviterAmount().send({
          from: this.wallet
        });
        if (result.status == true) {
          console.log("Claim Reward success");
          this.$message.success("Claim Reward success")
        } else {
          console.log("Claim Reward fail");
        }
        Toast.clear();
      } catch (e) {
        let errorList = e.message.replace("Internal JSON-RPC error.", "").split("execution reverted:");
        if (errorList.length > 1) {
          let result = errorList[1].split("\",")[0];
          this.$message.error(result)
        } else {
          this.$message.error("Claim Reward fail")
        }
        Toast.clear();
      }
    },
    async refund() {
      if (this.userInfo.depositValue <= 0 || this.userInfo.claimed) {
        return
      }

      Toast.loading({
        message: 'loading...',
        loadingType: 'spinner',
        duration: 0,
      })
      try {
        const refundContract = getContract("refund", RefundAbi);
        await refundContract.methods.refund().estimateGas({
          gas: 5000000,
          from: this.wallet
        });
        const result = await refundContract.methods.refund().send({
          from: this.wallet
        });
        if (result.status == true) {
          console.log("Refund success");
          this.$message.success("Refund success")
          Toast.clear();
        } else {
          console.log("Refund fail");
          Toast.clear();
        }
      } catch (e) {
        let errorList = e.message.replace("Internal JSON-RPC error.", "").split("execution reverted:");
        if (errorList.length > 1) {
          let result = errorList[1].split("\",")[0];
          this.$message.error(result)
        } else {
          this.$message.error("Claim fail")
        }
        Toast.clear();
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  /*background: rgba(162,209, 19, 1);*/
  min-height: 100vh;
  /*padding-left: 30px;*/
  /*padding-right: 30px;*/
  /*padding-top: 10px;*/
  padding-bottom: 20px;
  text-align: center;
  background: url("../assets/sapce.png") no-repeat center;
  background-size: 100% 100%;
  /*background-color: red;*/
  /*background-attachment: scroll;*/
  overflow: hidden;
}
.top-container {
  /*background: url("../assets/ibg.png") no-repeat;*/
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
}

.ibgc-container {
  /*background: url("../assets/ibgc.png") no-repeat;*/
  /*background-size: cover;*/
  background-color: rgba(52, 52, 40, 1);
}
.card {
  background: url("../assets/card.png") no-repeat center;
  background-size: 100% 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 680px;
}
.text-bold {
  font-weight: bold;
}
.clock-container {
  background: url("../assets/time.png") no-repeat center;
  background-size: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.bci {
  background: url("../assets/ibgc.png") no-repeat top;
  background-size: 100%;
}

.top-button {
  height: 40px;
  padding-left: 13px;
  padding-right: 13px;
  background: url("../assets/top-bt-bg.png") no-repeat center;
  background-size: 100% 100%;
}
.title {
  color: rgba(162,209, 19, 1);
  font-weight: bolder;
  font-size: 18px;
}
@media (min-width: 1201px) {
  /*.timeline {*/
  /*  display: flex;*/
  /*  flex-direction: row;*/
  /*  justify-content: flex-start;*/
  /*  align-items: flex-start;*/
  /*}*/
  .pc {
    display: block;
  }
  .mobile {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .pc {
    display: none;
  }
  .mobile {
    display: block;
  }
}

.font-size-36 {
  font-size: 36px
}
.height-20 {
  height: 20px
}
.font-size-20 {
  font-size: 20px;
}
.font-size-10 {
  font-size: 6px;
}
@media (min-width: 670px) {
  /*.pc {*/
  /*  display: none;*/
  /*}*/
  /*.mobile {*/
  /*  display: block;*/
  /*}*/
  /*.timeline {*/
  /*  display: flex;*/
  /*  flex-direction: row;*/
  /*  justify-content: flex-start;*/
  /*  align-items: flex-start;*/
  /*}*/
  /*.timeline-container {*/
  /*  display: flex;*/
  /*  flex-direction: column;*/
  /*}*/
  /*.timeline-tag-container {*/
  /*  height: 40px;*/
  /*  min-width: 40px;*/
  /*  display: flex;*/
  /*  flex-direction: row;*/
  /*}*/
  /*.timeline-tag-line-container {*/
  /*  height: 13px;*/
  /*  width: 100%;*/
  /*  display: flex;*/
  /*  flex-direction: row;*/
  /*  align-items: center;*/
  /*}*/
  /*.timeline-tag-line {*/
  /*  background-color: #C0C4CC;*/
  /*  height: 2px;*/
  /*  width: 100%;*/
  /*}*/
  /*.timeline-content-container {*/
  /*  margin-left: 30px;*/
  /*  flex: 1;*/
  /*}*/
}

.timeline {
  display: flex;
  flex-direction: column;
}
.timeline-container {
  display: flex;
  flex-direction: row;
}
.timeline-tag-container {
  width: 40px;
}
.timeline-tag-line-container {
  width: 13px;
  height: 100%;
}
.timeline-tag-line {
  background-color: #C0C4CC;
  width: 2px;
  height: 100%;
  margin: auto
}
.timeline-content-container {
  flex: 1;
}

@media all and (max-width: 669px) {

  .tokens {
    display: none;
    background-color: transparent;
  }

  .font-size-36 {
    font-size: 21px
  }
  .height-20 {
    height: 6px
  }
  .clock-container {
    background: url("../assets/time.png") no-repeat center;
    background-size: 100% 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .top-container {
    /*background: url("../assets/ibg.png") no-repeat;*/
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
  }
  .font-size-20 {
    font-size: 10px;
  }
  .font-size-10 {
    font-size: 6px;
  }

  .timeline {
    display: flex;
    flex-direction: column;
  }
  .timeline-container {
    display: flex;
    flex-direction: row;
  }
  .timeline-tag-container {
    width: 40px;
  }
  .timeline-tag-line-container {
    width: 13px;
    height: 100%;
  }
  .timeline-tag-line {
    background-color: #C0C4CC;
    width: 2px;
    height: 100%;
    margin: auto
  }
  .timeline-content-container {
    flex: 1;
  }

}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

<style>
/*.el-input__inner[DangerColor="danger"]{*/
/*  background-color: transparent;*/
/*}*/
/*.el-input__inner[WarningColor="warning"]{*/
/*  background-color: transparent;*/
/*}*/
/*.el-input__inner[SuccessColor="success"]{*/
/*  background-color: transparent;*/
/*}*/
/*.el-input__inner:hover{*/
/*  border-color: rgba(162,209, 19, 1);*/
/*  background-color: transparent;*/
/*}*/
</style>