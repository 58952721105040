<template>
  <div id="app">
    <Service msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import Service from './components/Service.vue'

export default {
  name: 'App',
  components: {
    Service
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  font-size: 15px;
  /*margin-top: 60px;*/
}
body{
  padding: 0;
  margin: 0;
}
/*.el-input__inner{*/
/*  border-color: red;*/
/*}*/
</style>
