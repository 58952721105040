export default {
    "Token’s more Ecological": "为Token生态而生",
    "Receive": "获得",
    "Burn": "销毁",
    "Total supply": "总供应量",
    "Your balance": "你的余额",
    "Claim": "领取",
    "Claimed": "已领取",
    "Redeem": "赎回",
    "Lend": "质押借币",
    "Token Distribution and Economic Plans": "代币分配模型",
    "Distribution of IDO Revenue": "募资分配模型",
    "Swap": "去中心交易所（无燃烧添加和移除流动性）",
    "Easy Create Token": "一键发代币",
    "Multi Send Token": "一键空投代币",
    "IDO": "IDO预售平台",
    "Create NFT": "一键创建NFT",
    "NFT Trade": "NFT 交易平台",
    "Token Robot": "抢币科学家",
    "Wallet Listener": "钱包监控提醒",
    "Days": "天",
    "Hours": "时",
    "Minutes": "分",
    "Min": "最小购买",
    "Max": "最大购买",
    "Coming Soon": "暂未开放",
    "Your Reward": "邀请奖励",
    "Claim Reward": "提取奖励",
    "Claim Reward2": "已提取",
    "Invite link": "邀请链接",
    "Invitations": "已邀请",
    "VeldRule-vertical": "VeldRule-vertical-zh",
    "VeldRule": "VeldRule-zh",
    "Hardcap": "剩余额度",
    "Refund balance": "可退款金额"
}