export default {
    "Token’s more Ecological": "Token’s more Ecological",
    "Receive": "Receive",
    "Burn": "Burn",
    "Total supply": "Total supply",
    "Your balance": "Your balance",
    "Claim": "Claim",
    "Claimed": "Claimed",
    "Redeem": "Redeem",
    "Lend": "Lend",
    "Token Distribution and Economic Plans": "Token Distribution and Economic Plans",
    "Distribution of IDO Revenue": "Distribution of IDO Revenue",
    "Swap": "Swap",
    "Easy Create Token": "Easy Create Token",
    "Multi Send Token": "Multi Send Token",
    "IDO": "IDO",
    "Create NFT": "Create NFT",
    "NFT Trade": "NFT Trade",
    "Token Robot": "Token Robot",
    "Wallet Listener": "Wallet Listener",
    "Days": "Days",
    "Hours": "Hours",
    "Minutes": "Minutes",
    "Min": "Min",
    "Max": "Max",
    "Coming Soon": "Coming Soon",
    "Your Reward": "Your Reward",
    "Claim Reward": "Claim Reward",
    "Claim Reward2": "Claimed Reward",
    "Invite link": "Invite link",
    "Invitations": "Invitations",
    "VeldRule-vertical": "VeldRule-vertical",
    "VeldRule": "VeldRule",
    "Hardcap": "Remaining amount",
    "Refund balance": "Refund balance",
}